<template>
    <section class="ourservices">
        <div class="title">
            <h1>Why Choose Us</h1>
            <p>Our commitment to excellence and innovation sets us apart from the competition.</p>
        </div>
        <div class="services-container">
            <div class="service-card yellow-border">
                <div class="icon yellow">
                    <i class="fas fa-bolt"></i>
                </div>
                <h2>Rapid Delivery</h2>
                <p>We deliver high-quality solutions within tight timeframes without compromising on quality.</p>
            </div>
            <div class="service-card green-border">
                <div class="icon green">
                    <i class="fas fa-shield-halved"></i>
                </div>
                <h2>Security First</h2>
                <p>We implement industry-leading security practices to protect your data and applications.</p>
            </div>
            <div class="service-card purple-border">
                <div class="icon purple">
                    <i class="fas fa-lightbulb"></i>
                </div>
                <h2>Scalable Solutions</h2>
                <p>Our solutions are built to scale with your business, adapting to your changing needs.</p>
            </div>
        </div>
        <div class="discover">
            <h1>Ready to transform your business?</h1>
            <p>Let's discuss how our services can help you achieve your digital goals.</p>
            <button @click.prevent="scrollToSection('#getintouch')">Get Started</button>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WhyChooseUs',
    methods: {
    scrollToSection(id) {
    this.isMenuOpen = false; // Close menu on mobile
    const section = document.querySelector(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 70, // Adjust for the header height
        behavior: "smooth",
      });
    }
  }
  }
};
</script>

<style scoped>
/* Main Section Styles */
.ourservices {
    background: #ffffff;
    padding: 20px 20px;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    position: relative;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

/* Title Section Styles */
.title {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
}

.title h1 {
    font-size: 40px;
    font-weight: 700;
    color: #1972D8;
    margin-bottom: 10px;
}

.title p {
    font-size: 18px;
    color: #0D3C72;
    max-width: 100%;
    margin: 0 auto;
}

/* Services Container Styles */
.services-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
}

/* Service Card Styles */
.service-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: left;
    flex: 1;
    min-width: 300px;
    max-width: 360px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
}

/* Vertical Lines for Service Cards */
.service-card.yellow-border {
    border-left: 4px solid #ff9a28;
}

.service-card.green-border {
    border-left: 4px solid #12b981;
}

.service-card.purple-border {
    border-left: 4px solid #9b4fff;
}

.service-card:hover {
    transform: translateY(-5px);
}

/* Icon Styles */
.icon {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
}

.icon.yellow {
    background: #fff6e8;
}

.icon.green {
    background: #e7f9f2;
}

.icon.purple {
    background: #f6edff;
}

.yellow i {
    color: #ff9a28;
    font-size: 24px;
}

.green i {
    color: #12b981;
    font-size: 24px;
}

.purple i {
    color: #9b4fff;
    font-size: 24px;
}

/* Card Content Styles */
.service-card h2 {
    font-size: 24px;
    font-weight: 700;
    color: #0D3C72;
    margin-bottom: 10px;
}

.service-card p {
    font-size: 16px;
    color: #64748B;
    margin-bottom: 20px;
}

/* Discover Section Styles */
.discover {
    max-width: 100%;
    width: 90%;
    background: linear-gradient(90deg, #0D3C72 0%, #1972D8 100%);
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    margin: 50px auto;
}

.discover h1 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.discover p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    max-width: 80%;
    margin: 0 auto;
}

.discover button {
    padding: 10px 20px;
    background: #FFFFFF;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 15px;
    color: #1a72d8;
}

/* Responsive Design */
@media (max-width: 768px) {
    .title h1 {
        font-size: 32px;
    }

    .title p {
        font-size: 16px;
    }

    .services-container {
        flex-direction: column;
        align-items: center;
    }

    .service-card {
        max-width: 100%;
    }

    .service-card h2 {
        font-size: 20px;
    }

    .service-card p {
        font-size: 14px;
    }

    .discover {
        padding: 20px;
    }

    .discover h1 {
        font-size: 24px;
    }

    .discover p {
        font-size: 14px;
        max-width: 90%;
    }

    .discover button {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .title h1 {
        font-size: 28px;
    }

    .title p {
        font-size: 14px;
    }

    .service-card {
        padding: 20px;
    }

    .icon {
        width: 50px;
        height: 50px;
    }

    .icon i {
        font-size: 20px;
    }
}
</style>