<template>
    <section class="ourservices">
        <div class="title">
            <h1>About Nizosoft</h1>
            <p>Building innovative digital solutions that empower businesses to thrive in the modern economy</p>
        </div>
        <div class="about-container">
            <div class="about">
                <h1>Our Story</h1>
                <p>Founded with a vision to simplify complex digital challenges, Nizosoft has grown from a small team of
                    passionate developers into a leading provider of enterprise-grade software solutions.</p>
                <p>Our journey began with the creation of robust authentication systems, which evolved into a
                    comprehensive suite of microservices designed to solve specific business challenges. Today, we offer
                    end-to-end solutions that help businesses streamline operations, enhance security, and deliver
                    exceptional user experiences.</p>
                <p>With a focus on innovation, reliability, and customer success, we continue to push the boundaries of
                    what's possible in software development, always staying ahead of industry trends and technological
                    advancements.</p>
            </div>
            <div class="brand-logo">
                <div class="logo-box">
                    <h1><span style="font-family: 'Anton', sans-serif;font-weight: 400;font-size: 117px;">N</span><span style="font-family: 'Anton', sans-serif;font-weight: 400;font-size: 87px;">izo</span><span style="font-family: 'Anton', sans-serif;font-weight: 400;font-size: 117px;">S</span><span style="font-family: 'Anton', sans-serif;font-weight: 400;font-size: 87px;">oft</span></h1>
                    <img class="gif top-left" src="../assets/element2.webp" alt="decorative gif">
                    <img class="gif top-right" src="../assets/element1.webp" alt="decorative gif">
                    <img class="gif bottom-left" src="../assets/element1.webp" alt="decorative gif">
                    <img class="gif bottom-right" src="../assets/element2.webp" alt="decorative gif">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutNizosoft',
};
</script>

<style scoped>
.ourservices {
    background: #F1F7FF;
    padding: 2rem 1rem;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
}

.title {
    text-align: center;
    margin-bottom: 2rem;
}

.title h1 {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    font-weight: 700;
    color: #1972D8;
    margin-bottom: 0.5rem;
}

.title p {
    font-size: clamp(0.9rem, 2.5vw, 1.125rem);
    color: #0D3C72;
    max-width: 600px;
    margin: 0 auto;
}

.about-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    gap: 2rem;
}

.about, .brand-logo {
    flex: 1;
    min-width: 0;
    padding: 1rem;
}

.about h1 {
    font-size: clamp(1.25rem, 3vw, 1.5rem);
    font-weight: 700;
    color: #0D3C72;
    margin-bottom: 0.5rem;
}

.about p {
    font-size: clamp(0.875rem, 2vw, 1rem);
    color: #64748B;
    margin-bottom: 0.625rem;
}

.brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-box {
    position: relative;
    width: min(300px, 100%);
    aspect-ratio: 1;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.logo-box h1 {
    background: linear-gradient(to bottom, #0D3C72, #1972D8);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: clamp(2rem, 10vw, 100%);
    text-align: center;
}

.gif {
    position: absolute;
    width: clamp(50px, 25%, 100px);
    height: clamp(50px, 25%, 100px);
}

.top-left { top: 0; left: 0; }
.top-right { top: 0; right: 0; }
.bottom-left { bottom: 0; left: 0; }
.bottom-right { bottom: 0; right: 0; }

@media (max-width: 768px) {
    .about, .brand-logo {
        flex: 100%;
    }
}
</style>