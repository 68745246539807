// router/index.js or wherever your routes are defined
import { createRouter, createWebHistory } from 'vue-router';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import HomePage from './components/Home.vue'
import TermsOfService from './components/TermsOfService.vue';
import CookiesPolicy from './components/CookiesPolicy.vue';

const routes = [
{ path: '/', name: 'Home', component: HomePage },
  { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/terms-of-service', name: 'TermsOfService', component: TermsOfService },
  { path: '/cookie-policy', name: 'CookiePolicy', component: CookiesPolicy }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;