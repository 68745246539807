<template>
    <section class="footer">
      <div class="footer-content">
        <!-- Left Section: Company Info -->
        <div class="footer-section company-info">
          <h2 class="logo">NizoSoft</h2>
          <p>
            We build amazing apps for Android & iOS, websites, and provide microservices like Payment and Auth services to help businesses thrive in the digital era.
          </p>
          <div class="social-icons">
            <a href="#" class="social-icon"><i class="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/nizosofttechnology" class="social-icon"><i class="fab fa-instagram"></i></a>
            <a href="https://www.linkedin.com/company/nizosoft" class="social-icon"><i class="fab fa-linkedin-in"></i></a>
            <a href="https://x.com/NizoSoft4518" class="social-icon"><i class="fab fa-x-twitter"></i></a>
          </div>
        </div>
  
        <!-- Middle Section: Company Links -->
        <div class="footer-section company-links">
          <h3>Company</h3>
          <ul>
            <li><a href="#" @click.prevent="scrollToSection('#about')">About Us</a></li>
            <li><a href="#" @click.prevent="scrollToSection('#services')">Services</a></li>
            <li><a href="#" @click.prevent="scrollToSection('#Leadership')">Leadership</a></li>
            <li><a href="#" @click.prevent="scrollToSection('#getintouch')">Contact</a></li>
          </ul>
        </div>
  
        <!-- Right Section: Newsletter -->
        <div class="footer-section newsletter">
          <h3>Stay Updated</h3>
          <p>Subscribe to our newsletter for the latest updates and news.</p>
          <form class="newsletter-form" @submit.prevent="subscribeToNewsletter">
            <input type="email" v-model="email" placeholder="Your Email" required />
            <button type="submit" :disabled="isSubmitting">
              {{ isSubmitting ? 'Subscribing...' : 'Subscribe' }}
            </button>
          </form>
          <p class="newsletter-terms">
            By subscribing, you agree to our <router-link to="/privacy-policy">Privacy Policy</router-link> and consent to receive updates from our company.
          </p>
        </div>
      </div>
  
      <!-- Bottom Section: Copyright and Links -->
      <div class="footer-bottom">
        <p class="copyright">© 2025 NizoSoft. All rights reserved.</p>
        <div class="footer-bottom-links">
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/terms-of-service">Terms of Service</router-link>
          <router-link to="/cookie-policy">Cookie Policy</router-link>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  
  export default {
    name: 'FooterView',
    mounted() {
    // Check if the current route is one of the specific routes
    if (this.$route.path === '/privacy-policy' || this.$route.path === '/terms-of-service' || this.$route.path === '/cookie-policy') {
      window.scrollTo(0, 0); // Scroll to top
    }
  },
    data() {
      return {
        email: '',
        isSubmitting: false,
      };
    },
    methods: {
      async subscribeToNewsletter() {
        if (!this.email) {
          toast.error('Please enter your email.');
          return;
        }
  
        this.isSubmitting = true;
  
        try {
          const response = await axios.post(
            'https://669ditrjw9.execute-api.ap-south-1.amazonaws.com/betav1/admin/v1/customerdetails',
            {
              contactPurpose: 'SUBSCRIBE',
              email: this.email,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
  
          console.log('Response:', response);
          toast.success('Subscribed successfully!');
  
          // Clear input field after successful submission
          this.email = '';
        } catch (error) {
          console.error('Subscription failed:', error);
          toast.error('Failed to subscribe. Please try again.');
        } finally {
          this.isSubmitting = false;
        }
      },
      scrollToSection(id) {
        this.isMenuOpen = false;
        const section = document.querySelector(id);
        if (section) {
          window.scrollTo({
            top: section.offsetTop - 70,
            behavior: 'smooth',
          });
        }
      },
    },
  };
  </script>
  
  
  <style scoped>
  /* General Footer Styling */
  .footer {
    background-color: #0D3C72; 
    color: #ffffff;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
  }
  
  /* Footer Content Layout */
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px;
  }
  
  /* Individual Footer Sections */
  .footer-section {
    flex: 1;
    min-width: 250px;
  }
  
  /* Company Info Section */
  .company-info .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .company-info p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Social Icons */
  .social-icons {
    display: flex;
    gap: 15px;
  }
  
  .social-icon {
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
    border: 1px solid #ffffff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
  }
  
  .social-icon:hover {
    background-color: #ffffff;
    color: #1a2a44;
  }
  
  /* Company Links Section */
  .company-links h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .company-links ul {
    list-style: none;
    padding: 0;
  }
  
  .company-links li {
    margin-bottom: 10px;
  }
  
  .company-links a {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
  }
  
  .company-links a:hover {
    text-decoration: underline;
  }
  
  /* Newsletter Section */
  .newsletter h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .newsletter p {
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .newsletter-form {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .newsletter-form input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #2a3b5a;
    color: #ffffff;
    font-size: 14px;
    flex: 1;
  }
  
  .newsletter-form input::placeholder {
    color: #cccccc;
  }
  
  .newsletter-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .newsletter-form button:hover {
    background-color: #0056b3;
  }
  
  .newsletter-terms {
    font-size: 12px;
    line-height: 1.5;
  }
  
  .newsletter-terms a {
    color: #ffffff;
    text-decoration: underline;
  }
  
  /* Footer Bottom Section */
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ffffff33;
    padding-top: 20px;
    margin-top: 20px;
    max-width: 1200px;
    margin: 20px auto 0;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .copyright {
    font-size: 12px;
  }
  
  .footer-bottom-links {
    display: flex;
    gap: 20px;
  }
  
  .footer-bottom-links a {
    color: #ffffff;
    text-decoration: none;
    font-size: 12px;
  }
  
  .footer-bottom-links a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .footer-section {
      margin-bottom: 30px;
    }
  
    .social-icons {
      justify-content: center;
    }
  
    .newsletter-form {
      flex-direction: column;
    }
  
    .newsletter-form input,
    .newsletter-form button {
      width: 100%;
    }
  
    .footer-bottom {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-bottom-links {
      margin-top: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      padding: 30px 15px;
    }
  
    .company-info .logo {
      font-size: 20px;
    }
  
    .company-info p,
    .newsletter p,
    .company-links a {
      font-size: 13px;
    }
  
    .newsletter-form input,
    .newsletter-form button {
      padding: 8px;
      font-size: 13px;
    }
  
    .social-icon {
      width: 25px;
      height: 25px;
      font-size: 16px;
    }
  }
  </style>