<template>
    <HeaderView id="home"/>
    <div class="cookie-policy">
      <header>
        <h1>Cookie Policy</h1>
        <p class="subtitle">For Nizosoft.com | Last Updated: {{ lastUpdated }}</p>
      </header>
      <section>
        <p class="intro">
          This Cookie Policy outlines how <strong>Nizosoft.com</strong> employs cookies and similar technologies on our website, <a href="https://nizosoft.com">https://nizosoft.com</a>. By accessing or using our website, you consent to the use of cookies as described herein.
        </p>
      </section>
  
      <section>
        <h2>1. What Are Cookies?</h2>
        <p>
          Cookies are small text files stored on your device when you visit a website. They enhance your experience by enabling features such as preference retention, usage analysis, and personalized content delivery.
        </p>
      </section>
  
      <section>
        <h2>2. Types of Cookies We Use</h2>
        <p>We utilize the following categories of cookies on our website:</p>
        <ul>
          <li><strong>Necessary Cookies:</strong> Essential for core website functionality, including navigation and access to secure areas.</li>
          <li><strong>Functional Cookies:</strong> Store your preferences (e.g., language or region) to offer a tailored user experience.</li>
          <li><strong>Performance Cookies:</strong> Collect data on website usage and performance to optimize functionality and user experience.</li>
          <li><strong>Advertising Cookies:</strong> Enable personalized advertisements based on your interests and browsing behavior.</li>
        </ul>
      </section>
  
      <section>
        <h2>3. How We Use Cookies</h2>
        <p>Cookies serve the following purposes on our website:</p>
        <ul>
          <li>Retain your login details and preferences.</li>
          <li>Analyze traffic and usage trends.</li>
          <li>Customize content and advertisements.</li>
          <li>Facilitate social media sharing features.</li>
        </ul>
      </section>
  
      <section>
        <h2>4. Third-Party Cookies</h2>
        <p>
          We may integrate third-party services, such as Google Analytics, which set cookies on our website. These third parties maintain their own privacy and cookie policies, over which we have no control.
        </p>
      </section>
  
      <section>
        <h2>5. Cookie Duration</h2>
        <p>
          Cookies may be session-based (expiring when you close your browser) or persistent (remaining on your device for a defined period, e.g., 30 days or 1 year), depending on their purpose.
        </p>
      </section>
  
      <section>
        <h2>6. Managing Cookie Preferences</h2>
        <p>
          You can control cookie settings through your browser, choosing to accept, reject, or delete cookies. Note that disabling cookies may limit certain website features. For guidance, refer to:
        </p>
        <ul>
          <li><a href="https://support.google.com/chrome/answer/95647">Google Chrome</a></li>
          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
          <li><a href="https://support.apple.com/en-us/HT201265">Safari</a></li>
          <li><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge</a></li>
        </ul>
      </section>
  
      <section>
        <h2>7. Contact Us</h2>
        <p>
          For inquiries or concerns regarding our cookie practices, please contact us at <a :href="'mailto:' + contactEmail">{{ contactEmail }}</a>.
        </p>
        <p>
          To learn more about our data practices, please review our <a href="/privacy-policy">Privacy Policy</a>.
        </p>
      </section>
    </div>
    <FooterView/>
  </template>
  
  <script>
  import HeaderView from './HeaderView.vue';
  import FooterView from './FooterView.vue';
  
  export default {
    name: 'CookiePolicy',
    components: {
      HeaderView,
      FooterView
    },
    data() {
      return {
        lastUpdated: 'March 28, 2025', // Updated to current date
        contactEmail: 'nizosoft@gmail.com' // Replace with actual contact email
      };
    }
  };
  </script>
  
  <style scoped>
  /* Base styles for a professional look */
  .cookie-policy {
    max-width: 90%;
    width: 800px;
    margin: 4rem auto;
    padding: 2rem;
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Professional font */
    text-align: left;
    box-sizing: border-box;
    color: #2d2d2d; /* Darker, professional gray */
  }
  
  header {
    border-bottom: 1px solid #e0e0e0; /* Subtle divider */
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  
  h1 {
    font-size: clamp(1.75rem, 5vw, 2.25rem);
    font-weight: 600; /* Bolder for emphasis */
    color: #1a1a1a;
    margin: 0;
  }
  
  .subtitle {
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    color: #666;
    margin-top: 0.5rem;
  }
  
  h2 {
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    font-weight: 500;
    color: #1a1a1a;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    line-height: 1.7; /* Improved readability */
    margin-bottom: 1.5rem;
  }
  
  .intro {
    font-size: clamp(1rem, 2.5vw, 1.1rem);
    color: #444;
  }
  
  ul {
    list-style-type: none; /* Remove default bullets */
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
  
  ul li {
    position: relative;
    padding-left: 1.5rem;
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    line-height: 1.7;
    margin-bottom: 0.75rem;
  }
  
  ul li:before {
    content: "•"; /* Custom bullet */
    color: #007bff; /* Professional blue accent */
    position: absolute;
    left: 0;
    font-size: 1.2rem;
  }
  
  ul ul {
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
  
  ul ul li:before {
    content: "–"; /* Subtle dash for nested items */
    color: #666;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .cookie-policy {
      padding: 1.5rem;
      margin: 4rem auto;
    }
  
    header {
      padding-bottom: 0.75rem;
      margin-bottom: 1.5rem;
    }
  
    h1 {
      font-size: clamp(1.5rem, 5vw, 2rem);
    }
  
    h2 {
      margin-top: 2rem;
      margin-bottom: 0.75rem;
    }
  
    ul li {
      padding-left: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .cookie-policy {
      padding: 1rem;
      margin: 5rem auto;
    }
  
    h1 {
      font-size: clamp(1.25rem, 5vw, 1.75rem);
    }
  
    h2 {
      margin-top: 1.5rem;
    }
  
    p, ul li {
      line-height: 1.6;
    }
  
    ul li {
      padding-left: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  
  @media (max-width: 320px) {
    .cookie-policy {
      padding: 0.75rem;
    }
  }
  </style>