<template>
    <section class="ourteam">
      <div class="title">
        <h1>Our Leadership Team</h1>
        <p>Meet the talented individuals who drive our vision and strategy</p>
      </div>
      <div class="profile-container">
        <div v-for="member in teamMembers" :key="member.id" class="profile-card">
          <!-- Profile Image / Initials Section -->
          <div class="profile-wrapper" :class="getBackgroundClass(member.id)">
            <div v-if="member.profilePic" class="profile-image">
              <img :src="member.profilePic" :alt="member.name" />
            </div>
            <div v-else class="profile-initials">
              {{ getInitials(member.name) }}
            </div>
          </div>
  
          <!-- Name and Role Section -->
          <div class="profile-info">
            <h2>{{ member.name }}</h2>
            <p>{{ member.role }}</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'OurLeadershipTeam',
    data() {
      return {
        teamMembers: [
          { id: 1, name: 'Aaditya Gupta', role: 'Co-Founder', profilePic: '' },
          { id: 2, name: 'Sandip Kushwaha', role: 'Co-Founder', profilePic: '' },
          { id: 3, name: 'Bikash Yadav', role: 'Software Engineer', profilePic: '' },
          { id: 4, name: 'Ashutosh Gupta', role: 'Product Designer', profilePic: '' },
          { id: 5, name: 'Pappu Pandit Kumhar', role: 'Head of Business Development and Marketing', profilePic: '' }
        ]
      };
    },
    methods: {
      getInitials(name) {
        if (!name) return '';
        const parts = name.split(' ');
        return parts.length > 1
          ? `${parts[0][0]}${parts[1][0]}`
          : parts[0][0];
      },
      getBackgroundClass(id) {
        const colors = ['blue-bg', 'green-bg', 'orange-bg', 'purple-bg'];
        return colors[id % colors.length];
      }
    }
  };
  </script>
  
  <style scoped>
  /* Main Section */
  .ourteam {
    background: #ffffff;
    padding: 20px 20px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
  }
  
  /* Title */
  .title {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .title h1 {
    font-size: 40px;
    font-weight: 700;
    color: #1972D8;
  }
  
  .title p {
    font-size: 18px;
    color: #0D3C72;
  }
  
  /* Profile Container */
  .profile-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
  
  /* Profile Card */
  .profile-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Profile Wrapper */
  .profile-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  /* Profile Image */
  .profile-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  /* Profile Initials */
  .profile-initials {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
  }
  
  /* Different Background Colors */
  .blue-bg {
    background-color: #1972D8;
  }
  .green-bg {
    background-color: #27AE60;
  }
  .orange-bg {
    background-color: #F2994A;
  }
  .purple-bg {
    background-color: #9B51E0;
  }
  
  /* Name and Role */
  .profile-info h2 {
    font-size: 18px;
    font-weight: 700;
    color: #0D3C72;
  }
  
  .profile-info p {
    font-size: 16px;
    color: #64748B;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .title h1 {
      font-size: 32px;
    }
  
    .profile-container {
      flex-direction: column;
      align-items: center;
    }
  
    .profile-card {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .title h1 {
      font-size: 28px;
    }
  
    .profile-initials {
      font-size: 20px;
    }
  }
  </style>
  