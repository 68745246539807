<template>
<HeaderView id="home"/>
<HeroView/>
<OurServices id="services"/>
<WhyChooseUs/>
<IndustryWeServe id="products"/>
<FeaturesOfModernSolution/>
<AboutNizosoft id="about"/>
<MissionValues/>
<OurLeadershipTeam id="Leadership"/>
<GetInTouch id="getintouch"/>
<FooterView/>
</template>

<script>
import HeaderView from './HeaderView.vue'
import HeroView from './HeroView.vue'
import OurServices from './OurServices.vue'
import WhyChooseUs from './WhyChooseUs.vue'
import IndustryWeServe from './IndustryWeServe.vue'
import FeaturesOfModernSolution from './FeaturesOfModernSolution.vue'
import AboutNizosoft from './AboutNizosoft.vue'
import MissionValues from './MissionValues.vue'
import OurLeadershipTeam from './OurLeadershipTeam.vue'
import GetInTouch from './GetInTouch.vue'
import FooterView from './FooterView.vue'

export default {
  name: 'HomePae',
  components: {
    HeaderView,
    HeroView,
    OurServices,
    WhyChooseUs,
    IndustryWeServe,
    FeaturesOfModernSolution,
    AboutNizosoft,
    MissionValues,
    OurLeadershipTeam,
    GetInTouch,
    FooterView
  }
}
</script>

<style scoped>
</style>
