import { createApp } from 'vue'
import App from './App.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import router from './router.js';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
const app = createApp(App);
app.use(Vue3Toastify, {
    position: 'top-right', // Position of the toast notifications
    timeout: 5000         // Duration in milliseconds before the toast disappears
    // Add more options here if needed, e.g., theme: 'light'
  });

app.use(router);
app.mount('#app');
