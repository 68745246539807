<template>
  <section class="hero">
    <div class="container">
      <div class="content fade-in">
        <h1 class="title">Building <span class="highlight">smarter</span> digital solutions</h1>
        <p class="subtitle">
          We develop amazing apps for Android & iOS, create responsive websites, and provide powerful microservices for
          authentication, payments, and more.
        </p>
        <div class="buttons">
          <button class="btn services" @click.prevent="scrollToSection('#services')"><i class="fas fa-cogs"></i> Our Services</button>
          <button class="btn contact" @click.prevent="scrollToSection('#getintouch')"><i class="fas fa-envelope"></i> Contact Us</button>
        </div>
        <div class="features">
          <div class="feature-item"><i class="fas fa-circle-check"></i>99.9% Uptime</div>
          <div class="feature-item"><i class="fas fa-lock"></i> Secure Solutions</div>
          <div class="feature-item"><i class="fas fa-bolt"></i> Fast Performance</div>
        </div>
      </div>
      <div class="ani-illustration">
        <img src="@/assets/animativeHerosection.gif" alt="Animated Illustration" />
      </div>
    </div>
    <div class="scroll-indicator">Scroll to explore</div>
  </section>
</template>

<script>

export default {
  name: 'HeroSection',
  methods: {
    scrollToSection(id) {
    this.isMenuOpen = false; // Close menu on mobile
    const section = document.querySelector(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 70, // Adjust for the header height
        behavior: "smooth",
      });
    }
  }
  }
};
</script>

<style scoped>
.hero {
  background: linear-gradient(98.43deg, #161A1F 5.68%, #0D3C72 99.57%);
  color: #fff;
  padding: 60px 0;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 30px;
}

.content {
  flex: 1;
  max-width: 600px;
  min-width: 0;
}

.title {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.1;
}

.highlight {
  color: #00ddeb;
}

.subtitle {
  font-size: 1.1rem;
  color: #e0e0e0;
  margin-bottom: 25px;
  line-height: 1.5;
}

.buttons {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn {
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.btn i {
  display: inline-block;
}

.services {
  background-color: #1972D8;
  color: #fff;
}

.contact {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.btn:hover {
  background-color: #00b8d4;
  border-color: #e0e0e0;
}

.features {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.feature-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #fff;
}

.feature-item i {
  margin-right: 8px;
  font-size: 1.2rem;
  color: #00ddeb;
}

.ani-illustration {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
}

.ani-illustration img {
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: contain;
}

.scroll-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9rem;
  color: #bbb;
}

.scroll-indicator::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00ddeb;
  margin-left: 8px;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Large tablets and small laptops */
@media (max-width: 1024px) {
  .title {
    font-size: 2.5rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .container {
    padding: 0 20px;
  }
  
  .ani-illustration img {
    max-width: 400px;
  }
}

/* Tablets */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    text-align: center;
    padding: 0 15px;
  }

  .content {
    max-width: 100%;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 0.95rem;
  }

  .buttons {
    justify-content: center;
  }

  .features {
    justify-content: center;
    gap: 15px;
  }

  .ani-illustration img {
    max-width: 350px;
  }
}

/* Mobile devices */
@media (max-width: 480px) {
  .hero {
    padding: 10px 0;
  }

  .title {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }

  .buttons {
    flex-direction: row; /* Keep buttons on same line */
    gap: 10px;
    justify-content: center;
  }

  .btn {
    padding: 8px 16px; /* Slightly smaller padding */
    font-size: 0.85rem; /* Slightly smaller font */
    flex: 1; /* Allow buttons to grow equally */
    max-width: 45%; /* Limit width to fit both buttons */
  }

  .features {
    flex-direction: row; /* Keep features on same line */
    gap: 10px;
    justify-content: center;
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .feature-item {
    font-size: 0.75rem; /* Smaller font size to fit all items */
    flex: 1; /* Allow equal distribution */
    min-width: 0; /* Prevent overflow */
    text-align: center;
  }

  .feature-item i {
    font-size: 1rem;
    margin-right: 5px;
  }

  .ani-illustration img {
    max-width: 250px;
  }

  .scroll-indicator {
    font-size: 0.8rem;
    bottom: 15px;
  }
}
</style>