<template>
    <section class="ourservices">
        <div class="title">
            <h1>Powerful Features for Modern Solutions</h1>
            <p>Our comprehensive suite of services is designed to power your digital infrastructure with security,
                scalability, and simplicity.</p>
        </div>
        <div class="services-container">
            <div class="service-card">
                <div class="icon yellow">
                    <i class="fas fa-lock"></i>
                </div>
                <h2>Enhanced Security</h2>
                <p>Enterprise-grade security protocols with end-to-end encryption, multi-factor authentication, and
                    advanced threat protection.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Data protection & privacy
                    </li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Compliance with standards
                    </li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Regular security audits</li>
                </ul>
            </div>
            <div class="service-card">
                <div class="icon green">
                    <i class="fas fa-bolt"></i>
                </div>
                <h2>Scalable Infrastructure</h2>
                <p>Built on cloud-native technologies that grow with your business, handling increasing loads without
                    performance degradation.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Auto-scaling capabilities
                    </li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Load balancing</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Distributed architecture</li>
                </ul>
            </div>
            <div class="service-card">
                <div class="icon blue">
                    <i class="fas fa-hexagon-nodes"></i>
                </div>
                <h2>Seamless Integration</h2>
                <p>Easily connect our services with your existing systems through well-documented APIs and pre-built
                    connectors.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> RESTful APIs</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Webhooks support</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> API for multiple languages
                    </li>
                </ul>
            </div>
            <div class="service-card">
                <div class="icon purple">
                    <i class="fas fa-shield-halved"></i>
                </div>
                <h2>Reliability & Uptime</h2>
                <p>Our services operate with 99.9%+ uptime, backed by redundant systems and continuous monitoring to ensure your operations never stop.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> High availability design</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Disaster recovery</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> 24/7 monitoring</li>
                </ul>
            </div>
            <div class="service-card">
                <div class="icon red">
                    <i class="fas fa-headset"></i>
                </div>
                <h2>Premium Support</h2>
                <p>Expert technical support available through multiple channels, with dedicated account managers for enterprise clients.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> 24/7 technical assistance</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Implementation assistance</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Comprehensive documentation</li>
                </ul>
            </div>
            <div class="service-card">
                <div class="icon pink">
                    <i class="fas fa-chart-simple"></i>
                </div>
                <h2>Comprehensive Analytics</h2>
                <p>Gain valuable insights with real-time data visualization, custom reports, and actionable intelligence for business decision-making.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Custom dashboards</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Data export options</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Predictive insights</li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'FeaturesOfModernSolution',
};
</script>

<style scoped>
/* Main Section Styles */
.ourservices {
    background: #ffffff;
    padding: 20px 20px;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

/* Title Section Styles */
.title {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
}

.title h1 {
    font-size: 40px;
    font-weight: 700;
    color: #1972D8;
    margin-bottom: 10px;
}

.title p {
    font-size: 18px;
    color: #0D3C72;
    max-width: 100%;
    margin: 0 auto;
}

/* Services Container Styles */
.services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}

/* Service Card Styles */
.service-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: left;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
}

.service-card:hover {
    transform: translateY(-5px);
}

/* Icon Styles */
.icon {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
}

.icon.yellow { background: #fff6e9; }
.icon.green { background: #e7f9f2; }
.icon.blue { background: #e9faff; }
.icon.purple { background: #f4e7f8; }
.icon.red { background: #ffe5e6; }
.icon.pink { background: #ffe9fa; }

.yellow i { color: #fe9a26; font-size: 24px; }
.green i { color: #12b981; font-size: 24px; }
.blue i { color: #27c2ff; font-size: 24px; }
.purple i { color: #930fb8; font-size: 24px; }
.red i { color: #fc0307; font-size: 24px; }
.pink i { color: #ff27d0; font-size: 24px; }

/* Card Content Styles */
.service-card h2 {
    font-size: 24px;
    font-weight: 700;
    color: #0D3C72;
    margin-bottom: 10px;
}

.service-card p {
    font-size: 16px;
    color: #64748B;
    margin-bottom: 20px;
}

.service-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.service-card ul li {
    font-size: 16px;
    color: #64748B;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.checkmark {
    color: #34C759;
    margin-right: 8px;
    font-size: 18px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .services-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .services-container {
        grid-template-columns: 1fr;
    }
    
    .title h1 {
        font-size: 32px;
    }

    .title p {
        font-size: 16px;
    }

    .service-card h2 {
        font-size: 20px;
    }

    .service-card p {
        font-size: 14px;
    }

    .service-card ul li {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .title h1 {
        font-size: 28px;
    }

    .title p {
        font-size: 14px;
    }

    .service-card {
        padding: 20px;
    }

    .icon {
        width: 50px;
        height: 50px;
    }

    .icon i {
        font-size: 20px;
    }
}
</style>