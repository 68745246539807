<template>
    <HeaderView id="home"/>
    <div class="terms-of-service">
      <header>
        <h1>Terms of Service</h1>
        <p class="subtitle">For Nizosoft.com | Last Updated: {{ lastUpdated }}</p>
      </header>
      <section>
        <p class="intro">
          Welcome to <strong>Nizosoft.com</strong>. These Terms of Service ("Terms") govern your access to and use of our website, <a href="https://nizosoft.com">https://nizosoft.com</a> (the "Site"). By accessing or using the Site, you agree to be bound by these Terms. If you do not agree, please refrain from using the Site.
        </p>
      </section>
  
      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          Your use of the Site constitutes your acceptance of these Terms and any additional terms or conditions we may provide. If you do not agree to these Terms, you must not access or use the Site.
        </p>
      </section>
  
      <section>
        <h2>2. Changes to Terms</h2>
        <p>
          We reserve the right to modify these Terms at any time. Changes will be posted on this page, and we will notify you by updating the "Last Updated" date. We encourage you to review these Terms periodically to stay informed of any updates.
        </p>
      </section>
  
      <section>
        <h2>3. User Accounts</h2>
        <p>
          If you create an account on the Site, you are responsible for safeguarding your account credentials and restricting access to your device. You accept full responsibility for all activities conducted under your account.
        </p>
      </section>
  
      <section>
        <h2>4. Intellectual Property</h2>
        <p>
          The Site, including its content, features, and functionality, is owned by Nizosoft and protected by international copyright, trademark, patent, trade secret, and other intellectual property laws. Unauthorized use is prohibited.
        </p>
      </section>
  
      <section>
        <h2>5. User Conduct</h2>
        <p>You agree not to:</p>
        <ul>
          <li>Engage in any illegal activities through the Site.</li>
          <li>Interfere with or disrupt the Site, its servers, or connected networks.</li>
          <li>Attempt unauthorized access to any part of the Site.</li>
          <li>Use automated tools (e.g., robots, spiders) to monitor or copy the Site without our prior written consent.</li>
        </ul>
      </section>
  
      <section>
        <h2>6. Disclaimers</h2>
        <p>
          The Site is provided "as is" without warranties of any kind, express or implied. Nizosoft does not guarantee that the Site will be uninterrupted, error-free, or free of viruses or other harmful components.
        </p>
      </section>
  
      <section>
        <h2>7. Indemnification</h2>
        <p>
          You agree to indemnify and hold harmless Nizosoft, its affiliates, officers, agents, and employees from any claims, demands, or damages, including reasonable attorneys' fees, arising from your use of the Site, violation of these Terms, or infringement of any third-party rights.
        </p>
      </section>
  
      <section>
        <h2>8. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of [State/Country], without regard to its conflict of law provisions.
        </p>
      </section>
  
      <section>
        <h2>9. Contact Us</h2>
        <p>
          For questions or concerns about these Terms, please reach out to us at <a :href="'mailto:' + contactEmail">{{ contactEmail }}</a>.
        </p>
      </section>
    </div>
    <FooterView/>
  </template>
  
  <script>
  import HeaderView from './HeaderView.vue';
  import FooterView from './FooterView.vue';
  
  export default {
    name: 'TermsOfService',
    components: {
      HeaderView,
      FooterView
    },
    data() {
      return {
        lastUpdated: 'March 28, 2025', // Updated to current date
        contactEmail: 'nizosoft@gmail.com' // Replace with actual contact email
      };
    }
  };
  </script>
  
  <style scoped>
  /* Base styles for a professional look */
  .terms-of-service {
    max-width: 90%;
    width: 800px;
    margin: 4rem auto;
    padding: 2rem;
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Professional font */
    text-align: left;
    box-sizing: border-box;
    color: #2d2d2d; /* Darker, professional gray */
  }
  
  header {
    border-bottom: 1px solid #e0e0e0; /* Subtle divider */
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  
  h1 {
    font-size: clamp(1.75rem, 5vw, 2.25rem);
    font-weight: 600; /* Bolder for emphasis */
    color: #1a1a1a;
    margin: 0;
  }
  
  .subtitle {
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    color: #666;
    margin-top: 0.5rem;
  }
  
  h2 {
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    font-weight: 500;
    color: #1a1a1a;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    line-height: 1.7; /* Improved readability */
    margin-bottom: 1.5rem;
  }
  
  .intro {
    font-size: clamp(1rem, 2.5vw, 1.1rem);
    color: #444;
  }
  
  ul {
    list-style-type: none; /* Remove default bullets */
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
  
  ul li {
    position: relative;
    padding-left: 1.5rem;
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    line-height: 1.7;
    margin-bottom: 0.75rem;
  }
  
  ul li:before {
    content: "•"; /* Custom bullet */
    color: #007bff; /* Professional blue accent */
    position: absolute;
    left: 0;
    font-size: 1.2rem;
  }
  
  ul ul {
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
  
  ul ul li:before {
    content: "–"; /* Subtle dash for nested items */
    color: #666;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .terms-of-service {
      padding: 1.5rem;
      margin: 4rem auto;
    }
  
    header {
      padding-bottom: 0.75rem;
      margin-bottom: 1.5rem;
    }
  
    h1 {
      font-size: clamp(1.5rem, 5vw, 2rem);
    }
  
    h2 {
      margin-top: 2rem;
      margin-bottom: 0.75rem;
    }
  
    ul li {
      padding-left: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .terms-of-service {
      padding: 1rem;
      margin: 5rem auto;
    }
  
    h1 {
      font-size: clamp(1.25rem, 5vw, 1.75rem);
    }
  
    h2 {
      margin-top: 1.5rem;
    }
  
    p, ul li {
      line-height: 1.6;
    }
  
    ul li {
      padding-left: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  
  @media (max-width: 320px) {
    .terms-of-service {
      padding: 0.75rem;
    }
  }
  </style>