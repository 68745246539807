<template>
  <header class="sticky-header">
    <div class="container">
      <div class="logo">
        <router-link style="text-decoration: none;" to="/">
        <span class="logotext large">N</span><span class="logotext small">izo</span>
        <span class="logotext large">S</span><span class="logotext small">oft</span>
    </router-link>
      </div>
      <nav :class="{ 'active': isMenuOpen }">
        <ul>
          <li v-for="link in links" :key="link.name">
            <a href="#" @click.prevent="scrollToSection(link.url)">{{ link.name }}</a>
          </li>
        </ul>
      </nav>
      <button class="requestbtn" @click.prevent="scrollToSection('#getintouch')" @click="closeMenu">Request Demo</button>
      <button class="menu-toggle" @click="toggleMenu" :class="{ 'open': isMenuOpen }">
        <span v-html="isMenuOpen ? '×' : '≡'"></span>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { name: "Home", url: "#home" },
        { name: "Services", url: "#services" },
        { name: "Products", url: "#products" },
        { name: "About", url: "#about" },
        { name: "Leadership", url: "#Leadership" },
      ],
      isMenuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    scrollToSection(id) {
  this.isMenuOpen = false; // Close menu on mobile
  if (this.$route.path !== "/") {
    // Navigate to Home and then scroll
    this.$router.push("/").then(() => {
      setTimeout(() => {
        this.scrollToSectionAfterNavigation(id);
      }, 500); // Delay to ensure Home page is loaded
    });
  } else {
    this.scrollToSectionAfterNavigation(id);
  }
},
scrollToSectionAfterNavigation(id) {
  const section = document.querySelector(id);
  if (section) {
    window.scrollTo({
      top: section.offsetTop - 70, // Adjust for the header height
      behavior: "smooth",
    });
  }
}
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
  font-family: 'Poppins', sans-serif;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.logo {
  font-size: clamp(20px, 4vw, 24px);
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: baseline;
}

.logotext {
  color: #1972D8;
  font-family: 'Anton', sans-serif;
  line-height: 1;
}

.large {
  font-size: clamp(24px, 5vw, 32px);
}

.small {
  font-size: clamp(18px, 3.5vw, 24px);
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

nav ul {
  list-style: none;
  display: flex;
  gap: clamp(15px, 2vw, 20px);
  margin: 0;
  padding: 0;
}

nav a {
  text-decoration: none;
  color: #010101;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  font-size: clamp(14px, 2vw, 16px);
  transition: color 0.3s ease;
}

nav a:hover {
  color: #1972D8;
}

.requestbtn {
  width: clamp(120px, 15vw, 150px);
  height: clamp(36px, 5vw, 46px);
  background: #1972D8;
  color: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 42px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: clamp(12px, 1.5vw, 14px);
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.requestbtn:hover {
  transform: scale(1.05);
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  width: clamp(40px, 5vw, 48px);
  height: clamp(40px, 5vw, 48px);
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
}

.menu-toggle span {
  display: block;
  font-size: clamp(28px, 4vw, 36px);
  color: #1972D8;
  font-weight: bold;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}

.menu-toggle:hover {
  background: rgba(25, 114, 216, 0.1);
}

.menu-toggle.open span {
  transform: translate(-50%, -50%) scale(1.2);
}

/* Media Queries */
@media (max-width: 1024px) {
  .container {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #ffffff;
    display: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  nav.active {
    display: block;
  }

  nav ul {
    flex-direction: column;
    padding: 20px;
    margin: 0 15px;
  }

  nav ul li {
    margin: 15px 0;
  }

  nav a {
    display: block;
    padding: 10px;
  }

  .requestbtn {
    margin: 15px auto 20px;
    display: block;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0 10px;
  }

  .sticky-header {
    padding: 5px 0;
  }

  nav ul {
    padding: 15px;
    margin: 0 10px;
  }

  nav a {
    padding: 8px;
  }

  .menu-toggle {
    width: clamp(36px, 5vw, 40px);
    height: clamp(36px, 5vw, 40px);
  }
}

/* Animation for mobile menu */
nav.active {
  animation: slideIn 0.3s ease-in-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>