<template>
    <section class="ourservices">
      <div class="title">
        <h1>Get in Touch</h1>
        <p>Have questions about our services? Ready to start your project? Contact us today.</p>
      </div>
      <div class="container">
        <div class="message-container">
          <h2>Send Us a Message</h2>
          <form class="input-container" @submit.prevent="submitForm">
            <div class="input-row">
              <div class="input-group">
                <label for="fullName">Full Name *</label>
                <input
                  type="text"
                  id="fullName"
                  v-model="fullName"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div class="input-group">
                <label for="email">Email Address *</label>
                <input
                  type="email"
                  id="email"
                  v-model="email"
                  placeholder="Enter Email"
                  required
                />
              </div>
            </div>
            <div class="input-group">
              <label for="company">Company</label>
              <input
                type="text"
                id="company"
                v-model="company"
                placeholder="Your Company Name"
              />
            </div>
            <div class="input-group">
              <label for="subject">Subject *</label>
              <input
                type="text"
                id="subject"
                v-model="subject"
                placeholder="How we can help you?"
                required
              />
            </div>
            <div class="input-group">
              <label for="interestedIn">Interested In</label>
              <input
                type="text"
                id="interestedIn"
                placeholder="Enter Service"
              />
            </div>
            <div class="input-group">
              <label for="message">Message *</label>
              <textarea
                id="message"
                v-model="message"
                placeholder="Your Message"
                required
              ></textarea>
            </div>
            <div class="checkbox-group">
              <input
                type="checkbox"
                id="privacy"
                v-model="privacyAgreed"
                required
              />
              <label for="privacy">
                I agree to the Privacy Policy and consent to having my data processed.
              </label>
            </div>
            <button type="submit" :disabled="isSubmitting">
              {{ isSubmitting ? 'Sending...' : 'Send Message' }}
            </button>
          </form>
        </div>
        <div class="contact-info">
            <div class="contact-details">
                    <h3>Let's Start A Conversation</h3>
                    <p>We're excited to hear from you and learn how we can build exceptional digital experiences.</p>
                    <div class="contact-item">
                        <span class="icon"><i class="fas fa-phone"></i></span>
                        <p>+977 9898989898</p>
                    </div>
                    <div class="contact-item">
                        <span class="icon"><i class="fas fa-envelope"></i></span>
                        <p>nizosoft@gmail.com</p>
                    </div>
                    <div class="contact-item">
                        <span class="icon"><i class="fas fa-location-dot"></i></span>
                        <p>Nepal</p>
                    </div>
                </div>
                <div class="services-offered">
                    <h3>What Services Does Nizsoft Offer?</h3>
                    <ul>
                        <li>EAUTH: Authentication And Identity Management Services</li>
                        <li>SMS: Subscriber Management System For Handling Subscriptions</li>
                        <li>EPAY: Payment Processing And Management Solutions</li>
                        <li>Custom Software Development For Web And Mobile Platforms</li>
                    </ul>
                </div>
                <div class="social-links">
                    <h3>Connect With Us</h3>
                    <div class="social-icons">
                        <a href="#" class="icon"><i class="fab fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/nizosofttechnology" class="icon"><i class="fab fa-instagram"></i></a>
                        <a href="#" class="icon"><i class="fab fa-linkedin-in"></i></a>
                        <a href="#" class="icon"><i class="fab fa-x-twitter"></i></a>
                    </div>
                </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  import { toast } from 'vue3-toastify';
   import 'vue3-toastify/dist/index.css';
  
   export default {
  name: 'GetInTouch',
  data() {
    return {
      fullName: '',
      email: '',
      company: '',
      subject: '',
      message: '',
      privacyAgreed: false,
      isSubmitting: false,
    };
  },
  methods: {
    async submitForm() {
      if (!this.privacyAgreed) {
        toast.error('Please agree to the Privacy Policy'); 
        return;
      }

      if (!this.fullName || !this.email || !this.subject || !this.message) {
        toast.error('Please fill all required fields');
        return;
      }

      this.isSubmitting = true;

      try {
        const response = await axios.post(
          'https://669ditrjw9.execute-api.ap-south-1.amazonaws.com/betav1/admin/v1/customerdetails',
          {
            fullName: this.fullName,
            email: this.email,
            subject: this.subject,
            message: this.message,
            company: this.company,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('responsecheck', response);
        toast.success('Message sent successfully');
        // Reset the form
        this.fullName = '';
        this.email = '';
        this.company = '';
        this.subject = '';
        this.message = '';
        this.privacyAgreed = false;
      } catch (error) {
        console.error('Error sending message:', error);
        toast.error('Failed to send message. Please try again later.');
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
  </script>
  
  <style scoped>
  /* Main Section Styles */
  .ourservices {
      background: #f9fafb;
      padding: 40px 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Roboto', sans-serif;
      box-sizing: border-box;
  }
  
  /* Title Section Styles */
  .title {
      text-align: center;
      margin-bottom: 40px;
  }
  
  .title h1 {
      font-size: 36px;
      font-weight: 700;
      color: #1673d8;
      margin-bottom: 10px;
  }
  
  .title p {
      font-size: 16px;
      color: #64748B;
      max-width: 600px;
      margin: 0 auto;
  }
  
  /* Container Styles */
  .container {
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;
      max-width: 1200px;
      width: 100%;
  }
  
  /* Message Container Styles */
  .message-container {
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      padding: 30px;
      flex: 1;
      min-width: 300px;
      max-width: 500px;
  }
  
  .message-container h2 {
      font-size: 24px;
      font-weight: 700;
      color: #0D3C72;
      margin-bottom: 20px;
  }
  
  /* Input Container Styles */
  .input-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
  }
  
  .input-row {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
  }
  
  .input-group {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 200px;
  }
  
  .input-group label {
      font-size: 14px;
      color: #0D3C72;
      margin-bottom: 5px;
  }
  
  .input-group input,
  .input-group textarea {
      padding: 10px;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      font-size: 14px;
      color: #64748B;
      background: #f9fafb;
      transition: border-color 0.3s ease;
  }
  
  .input-group input:focus,
  .input-group textarea:focus {
      border-color: #1972D8;
      outline: none;
  }
  
  .input-group textarea {
      resize: vertical;
      min-height: 100px;
  }
  
  .checkbox-group {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      color: #64748B;
  }
  
  .checkbox-group input {
      width: 16px;
      height: 16px;
  }
  
  .input-container button {
      background: #1972D8;
      color: #fff;
      padding: 12px;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background 0.3s ease;
  }
  
  .input-container button:hover {
      background: #1557a5;
  }
  
  /* Contact Info Styles */
  .contact-info {
      position: relative;
      background: linear-gradient(50.43deg, #0D3C72 50.68%, #161A1F 5.57%);
      background-size: cover;
      color: #fff;
      border-radius: 10px;
      padding: 30px;
      flex: 1;
      min-width: 300px;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 20px;
  }
  
  .contact-info::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      /* Dark overlay for better text readability */
      border-radius: 10px;
  }
  
  .contact-details,
  .services-offered,
  .social-links {
      position: relative;
      z-index: 1;
  }
  
  .contact-details h3,
  .services-offered h3,
  .social-links h3 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
  }
  
  .contact-details p {
      font-size: 14px;
      color: #d1d5db;
      margin-bottom: 20px;
  }
  
  .contact-item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
  }
  
  .contact-item .icon {
      font-size: 18px;
  }
  
  .contact-item p {
      font-size: 14px;
      margin: 0;
  }
  
  .services-offered ul {
      list-style: none;
      padding: 0;
      font-size: 14px;
      color: #d1d5db;
  }
  
  .services-offered li {
      margin-bottom: 10px;
  }
  
  .social-links .social-icons {
      display: flex;
      gap: 15px;
  }
  
  .social-links .icon {
      font-size: 20px;
      color: #fff;
      transition: color 0.3s ease;
  }
  
  .social-links .icon:hover {
      color: #1972D8;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
      .title h1 {
          font-size: 28px;
      }
  
      .title p {
          font-size: 14px;
      }
  
      .container {
          flex-direction: column;
          align-items: center;
      }
  
      .message-container,
      .contact-info {
          max-width: 100%;
      }
  
      .input-row {
          flex-direction: column;
      }
  }
  
  @media (max-width: 480px) {
      .title h1 {
          font-size: 24px;
      }
  
      .title p {
          font-size: 13px;
      }
  
      .message-container,
      .contact-info {
          padding: 20px;
      }
  
      .message-container h2,
      .contact-details h3,
      .services-offered h3,
      .social-links h3 {
          font-size: 18px;
      }
  }
  </style>