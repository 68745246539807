<template>
    <HeaderView id="home"/>
    <div class="privacy-policy">
      <header>
        <h1>Privacy Policy</h1>
        <p class="subtitle">For Nizosoft.com | Last Updated: {{ lastUpdated }}</p>
      </header>
      <section>
        <p class="intro">
          At <strong>Nizosoft.com</strong>, we are committed to safeguarding your privacy. This Privacy Policy outlines how we collect, use, and share your personal information when you visit or interact with our website, <a href="https://nizosoft.com">https://nizosoft.com</a>. Our goal is to ensure transparency in our data practices and protect your personal information.
        </p>
      </section>
  
      <section>
        <h2>1. Information We Collect</h2>
        <p>We collect the following types of information when you use our website:</p>
        <ul>
          <li><strong>Personal Information:</strong> Data you voluntarily provide, such as your name, email address, phone number, or other contact details when registering, subscribing to our newsletter, or contacting us.</li>
          <li><strong>Usage Data:</strong> Information automatically collected about your interaction with the website, including IP address, browser type, device details, pages visited, and time spent on the site.</li>
          <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tools to enhance your browsing experience, analyze website usage, and deliver personalized content or advertisements.</li>
        </ul>
      </section>
  
      <section>
        <h2>2. How We Use Your Information</h2>
        <p>We use the collected information for the following purposes:</p>
        <ul>
          <li><strong>Service Delivery:</strong> To operate the website and provide the features or services you request.</li>
          <li><strong>Communication:</strong> To respond to inquiries, offer customer support, or send updates about our services.</li>
          <li><strong>Improvement:</strong> To analyze usage patterns and feedback to enhance website functionality and user experience.</li>
          <li><strong>Marketing:</strong> To send promotional materials, newsletters, or offers (only with your explicit consent).</li>
          <li><strong>Legal Compliance:</strong> To comply with applicable laws, regulations, or to protect our legal rights.</li>
        </ul>
      </section>
  
      <section>
        <h2>3. Sharing of Information</h2>
        <p>We may share your information under the following circumstances:</p>
        <ul>
          <li><strong>Service Providers:</strong> With third-party vendors who assist in website operations, such as hosting providers, analytics services, or payment processors.</li>
          <li><strong>Legal Authorities:</strong> When required by law, court order, or to safeguard the safety, rights, or property of ourselves or others.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.</li>
        </ul>
        <p><em>We do not sell your personal information to third parties for their marketing purposes.</em></p>
      </section>
  
      <section>
        <h2>4. Your Rights</h2>
        <p>Depending on your location, you may have the following rights regarding your personal information:</p>
        <ul>
          <li><strong>Access:</strong> Request a copy of the personal data we hold about you.</li>
          <li><strong>Correction:</strong> Request corrections to inaccurate or incomplete information.</li>
          <li><strong>Deletion:</strong> Request the deletion of your personal data.</li>
          <li><strong>Objection:</strong> Object to specific data processing activities (e.g., marketing).</li>
        </ul>
        <p>
          To exercise these rights, contact us at <a :href="'mailto:' + contactEmail">{{ contactEmail }}</a>. We will respond in accordance with applicable privacy laws, such as the GDPR for EU residents or the CCPA for California residents.
        </p>
      </section>
  
      <section>
        <h2>5. Security</h2>
        <p>
          We employ reasonable technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no online system is entirely secure, and we cannot guarantee absolute security.
        </p>
      </section>
  
      <section>
        <h2>6. Cookies and Tracking Technologies</h2>
        <p>We use cookies and similar technologies to:</p>
        <ul>
          <li>Remember your preferences.</li>
          <li>Analyze site usage.</li>
          <li>Deliver tailored content.</li>
        </ul>
        <p>
          You may manage or disable cookies via your browser settings, though this may impact certain website features.
        </p>
      </section>
  
      <section>
        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. Updates will be posted here with a revised "Last Updated" date. We encourage regular review of this policy.
        </p>
      </section>
  
      <section>
        <h2>8. Contact Us</h2>
        <p>For questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:</p>
        <ul class="contact-list">
          <li><strong>Email:</strong> <a :href="'mailto:' + contactEmail">{{ contactEmail }}</a></li>
          <li><strong>Website:</strong> <a href="https://nizosoft.com/">https://nizosoft.com</a></li>
        </ul>
      </section>
    </div>
    <FooterView/>
  </template>
  
  <script>
  import HeaderView from './HeaderView.vue';
  import FooterView from './FooterView.vue';
  
  export default {
    name: 'PrivacyPolicy',
    components: {
      HeaderView,
      FooterView
    },
    data() {
      return {
        lastUpdated: 'March 28, 2025', // Updated to current date
        contactEmail: 'nizosoft@gmail.com' // Replace with actual contact email
      };
    }
  };
  </script>
  
  <style scoped>
  /* Base styles for a professional look */
  .privacy-policy {
    max-width: 90%;
    width: 800px;
    margin: 4rem auto;
    padding: 2rem;
    padding-top: 5rem; /* Added to push content below header; adjust based on HeaderView height */
    font-family: 'Helvetica Neue', Arial, sans-serif;
    text-align: left;
    box-sizing: border-box;
    color: #2d2d2d;
  }
  
  header {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  
  h1 {
    font-size: clamp(1.75rem, 5vw, 2.25rem);
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
  }
  
  .subtitle {
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    color: #666;
    margin-top: 0.5rem;
  }
  
  h2 {
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    font-weight: 500;
    color: #1a1a1a;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    line-height: 1.7;
    margin-bottom: 1.5rem;
  }
  
  .intro {
    font-size: clamp(1rem, 2.5vw, 1.1rem);
    color: #444;
  }
  
  ul {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 1.5rem;
  }
  
  ul li {
    position: relative;
    padding-left: 1.5rem;
    font-size: clamp(0.9rem, 2.5vw, 1rem);
    line-height: 1.7;
    margin-bottom: 0.75rem;
  }
  
  ul li:before {
    content: "•";
    color: #007bff;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
  }
  
  ul ul {
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
  
  ul ul li:before {
    content: "–";
    color: #666;
  }
  
  .contact-list li:before {
    content: none;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  em {
    font-style: italic;
    color: #555;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .privacy-policy {
      padding: 1.5rem;
      padding-top: 4rem; /* Adjusted for smaller header on tablets */
      margin: 1.5rem auto;
    }
  
    header {
      padding-bottom: 0.75rem;
      margin-bottom: 1.5rem;
    }
  
    h1 {
      font-size: clamp(1.5rem, 5vw, 2rem);
    }
  
    h2 {
      margin-top: 2rem;
      margin-bottom: 0.75rem;
    }
  
    ul li {
      padding-left: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-policy {
      padding: 1rem;
      padding-top: 3.5rem; /* Adjusted for mobile header height */
      margin: 4rem auto;
    }
  
    h1 {
      font-size: clamp(1.25rem, 5vw, 1.75rem);
    }
  
    h2 {
      margin-top: 1.5rem;
    }
  
    p, ul li {
      line-height: 1.6;
    }
  
    ul li {
      padding-left: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  
  @media (max-width: 320px) {
    .privacy-policy {
      padding: 0.75rem;
      padding-top: 3rem; /* Further adjusted for very small screens */
    }
  }
  </style>