<template>
    <section class="ourservices">
        <div class="title">
            <h1>Mission & Values</h1>
            <p>Our mission is to empower businesses with intelligent, secure, and scalable software solutions that drive growth and innovation.</p>
        </div>
        <div class="services-container">
            <div class="service-card">
                <div class="icon yellow">
                    <i class="fas fa-shield-halved"></i>
                </div>
                <h2>Trust & Security</h2>
                <p>We prioritize the security and privacy of our clients' data, implementing rigorous standards and continuously improving our security measures to maintain trust.</p>
            </div>
            <div class="service-card">
                <div class="icon green">
                    <i class="fas fa-bolt"></i>
                </div>
                <h2>Innovation</h2>
                <p>We constantly push the boundaries of technology, exploring new ideas and approaches to solve complex problems and deliver exceptional value to our clients.</p>
            </div>
            <div class="service-card">
                <div class="icon purple">
                    <i class="fas fa-users-line"></i>
                </div>
                <h2>Client Success</h2>
                <p>We measure our success by the success of our clients. We build lasting partnerships and go above and beyond to ensure our solutions drive real business outcomes.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MissionValues',
};
</script>

<style scoped>
/* Main Section Styles */
.ourservices {
    background: #ffffff;
    padding: 20px 20px;
    min-height: 500px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Roboto', sans-serif;
    position: relative;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

/* Title Section Styles */
.title {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
}

.title h1 {
    font-size: 40px;
    font-weight: 700;
    color: #1972D8;
    margin-bottom: 10px;
}

.title p {
    font-size: 18px;
    color: #0D3C72;
    max-width: 100%;
    margin: 0 auto;
}

/* Services Container Styles */
.services-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
}

/* Service Card Styles */
.service-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: left;
    flex: 1;
    min-width: 300px;
    max-width: 360px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
}

.service-card:hover {
    transform: translateY(-5px);
}

/* Icon Styles */
.icon {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
}

.icon.yellow {
    background: #fff6e8;
}

.icon.green {
    background: #e7f9f2;
}

.icon.purple {
    background: #f6edff;
}

.yellow i {
    color: #ff9a28;
    font-size: 24px;
}

.green i {
    color: #12b981;
    font-size: 24px;
}

.purple i {
    color: #9b4fff;
    font-size: 24px;
}

/* Card Content Styles */
.service-card h2 {
    font-size: 24px;
    font-weight: 700;
    color: #0D3C72;
    margin-bottom: 10px;
}

.service-card p {
    font-size: 16px;
    color: #64748B;
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .title h1 {
        font-size: 32px;
    }

    .title p {
        font-size: 16px;
    }

    .services-container {
        flex-direction: column;
        align-items: center;
    }

    .service-card {
        max-width: 100%;
    }

    .service-card h2 {
        font-size: 20px;
    }

    .service-card p {
        font-size: 14px;
    }

    .discover {
        padding: 20px;
    }

    .discover h1 {
        font-size: 24px;
    }

    .discover p {
        font-size: 14px;
        max-width: 90%;
    }

    .discover button {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .title h1 {
        font-size: 28px;
    }

    .title p {
        font-size: 14px;
    }

    .service-card {
        padding: 20px;
    }

    .icon {
        width: 50px;
        height: 50px;
    }

    .icon i {
        font-size: 20px;
    }
}
</style>