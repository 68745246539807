<template>
    <section class="ourservices">
        <div>
            <div class="title">
                <h1>Industries We Serve</h1>
                <p>We cater to a diverse range of industries, providing tailored software solutions that enhance
                    efficiency and innovation</p>
            </div>
            <div class="image-container">
                <div class="image-card" v-for="(industry, index) in industries" :key="index">
                    <div class="image-wrapper">
                        <img :src="industry.image" :alt="industry.title">
                        <div class="overlay"></div>
                        <h2 class="card-title">{{ industry.title }}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="products">
            <div class="title">
                <h1>Product</h1>
                <p>We offer innovative and scalable software solutions tailored to various industries.</p>
            </div>
            <div class="image-container">
                <div class="image-card" v-for="(products, index) in products" :key="index">
                    <div class="image-wrapper">
                        <img :src="products.image" :alt="products.title">
                        <!-- <div class="overlay"></div> -->
                        <!-- <h2 class="card-title">{{ products.title }}</h2> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'IndustryWeServe',
    data() {
        return {
            industries: [
                { title: 'Booking Industries', image: require('../assets/industryimg1.webp') },
                { title: 'Delivery Industries', image: require('../assets/industryimg2.webp') },
                { title: 'Management Systems', image: require('../assets/industryimg3.webp') },
                { title: 'Shopping Industries', image: require('../assets/industryimg4.webp') }
            ],
            products: [
                { title: 'NextHome4u', image: require('../assets/nextHome4u.webp') },
            ]
        };
    }
};
</script>

<style scoped>
/* Ensure box-sizing is applied globally */
* {
    box-sizing: border-box;
}

/* Main Section Styles */
.ourservices {
    background: #ffffff;
    padding: 20px 20px;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    position: relative;
    margin: 0;
    overflow-x: hidden;
}

/* Title Section Styles */
.title {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
}

.title h1 {
    font-size: 40px;
    font-weight: 700;
    color: #1972D8;
    margin-bottom: 10px;
    white-space: nowrap;
}

.title p {
    font-size: 18px;
    color: #0D3C72;
    max-width: 100%;
    margin: 0 auto;
}

/* Services Container Styles */
.image-container {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
}

/* Service Card Styles */
.image-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    min-width: 300px;
    max-width: 360px;
    height: 200px;
    /* Fixed height for all cards */
    padding: 0;
    overflow: hidden;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
}

.image-card:hover {
    transform: translateY(-5px);
}

/* Image Wrapper Styles */
.image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    /* Matches the card height */
}

.image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-wrapper .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.image-wrapper .card-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
}

.products{
    background: #F1F7FF;
    width: 100%;
    padding-bottom: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .title h1 {
        font-size: 32px;
    }

    .title p {
        font-size: 16px;
    }

    .image-container {
        flex-direction: column;
        align-items: center;
    }

    .image-card {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        height: 200px;
        /* Explicitly enforce height */
    }
}

@media (max-width: 480px) {
    .title h1 {
        font-size: 28px;
    }

    .title p {
        font-size: 14px;
    }

    .image-card {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        height: 200px;
        /* Explicitly enforce height */
        padding: 0;
    }
}
</style>