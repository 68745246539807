<template>
    <section class="ourservices">
        <div class="title">
            <h1>Our Services</h1>
            <p>We provide comprehensive digital solutions to help businesses thrive in the digital landscape.</p>
        </div>
        <div class="services-container">
            <!-- Card 1: App Development -->
            <div class="service-card">
                <div class="icon blue">
                    <i class="fas fa-mobile-alt"></i>
                </div>
                <h2>App Development</h2>
                <p>We build high-performance, feature-rich applications for Android and iOS platforms.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Seamless Integration</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Intuitive UI/UX</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Native & Cross-platform</li>
                </ul>
                <a href="#" class="learn-more">Learn More <i class="fas fa-arrow-right"></i></a>
            </div>
            <!-- Card 2: Website Development -->
            <div class="service-card">
                <div class="icon green">
                    <i class="fas fa-desktop"></i>
                </div>
                <h2>Website Development</h2>
                <p>We craft responsive, high-performance websites tailored to your business needs.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Responsive Design</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> SEO Optimization</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Fast Performance</li>
                </ul>
                <a href="#" class="learn-more">Learn More <i class="fas fa-arrow-right"></i></a>
            </div>
            <!-- Card 3: API & Microservices -->
            <div class="service-card">
                <div class="icon purple">
                    <i class="fas fa-network-wired"></i>
                </div>
                <h2>API & Microservices</h2>
                <p>We develop scalable, secure APIs and microservices to power your applications.</p>
                <ul>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> RESTful APIs</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Cloud Integration</li>
                    <li><span class="checkmark"><i class="fas fa-circle-check"></i></span> Scalable Architecture</li>
                </ul>
                <a href="#" class="learn-more">Learn More <i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurServices',
};
</script>

<style scoped>
/* Main Section Styles */
.ourservices {
    background: #F1F7FF;
    padding: 60px 20px;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

/* Title Section Styles */
.title {
    text-align: center;
    margin-bottom: 40px;
    width: 100%;
}

.title h1 {
    font-size: 40px;
    font-weight: 700;
    color: #1972D8;
    margin-bottom: 10px;
}

.title p {
    font-size: 18px;
    color: #0D3C72;
    max-width: 100%;
    margin: 0 auto;
}

/* Services Container Styles */
.services-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
}

/* Service Card Styles */
.service-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: left;
    flex: 1;
    min-width: 300px;
    max-width: 360px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
}



.service-card:hover {
    transform: translateY(-5px);
}

/* Icon Styles */
.icon {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0; /* Align icon to the left */
}

.icon.blue {
    background: #e7f1fc;
}

.icon.green {
    background: #e7f9f2;
}

.icon.purple {
    background: #f6edff;
}

.blue i{
    color: #3382dd;
    font-size: 24px;
}

.green i{
    color: #12b981;
    font-size: 24px;
}

.purple i{
    color: #9b4fff;
    font-size: 24px;
}

/* Card Content Styles */
.service-card h2 {
    font-size: 24px;
    font-weight: 700;
    color: #0D3C72;
    margin-bottom: 10px;
}

.service-card p {
    font-size: 16px;
    color: #64748B;
    margin-bottom: 20px;
}

.service-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.service-card ul li {
    font-size: 16px;
    color: #64748B;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.checkmark {
    color: #34C759;
    margin-right: 8px;
    font-size: 18px;
}

/* Learn More Link Styles */
.learn-more {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    color: #4A90E2;
    text-decoration: none;
    transition: color 0.3s ease;
}

.learn-more i {
    margin-left: 8px;
}

.learn-more:hover {
    color: #1E3A8A;
}

/* Responsive Design */
@media (max-width: 768px) {
    .title h1 {
        font-size: 32px;
    }

    .title p {
        font-size: 16px;
    }

    .services-container {
        flex-direction: column;
        align-items: center;
    }

    .service-card {
        max-width: 100%;
    }

    .service-card h2 {
        font-size: 20px;
    }

    .service-card p {
        font-size: 14px;
    }

    .service-card ul li {
        font-size: 14px;
    }

    .learn-more {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .title h1 {
        font-size: 28px;
    }

    .title p {
        font-size: 14px;
    }

    .service-card {
        padding: 20px;
    }

    .icon {
        width: 50px;
        height: 50px;
    }

    .icon i {
        font-size: 20px;
    }
}
</style>